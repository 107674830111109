<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6" style="margin: 0 auto">
          <h3>Profile</h3>
          <div class="form-group">
            <label>First Name</label>
            <input type="text" v-model="form.first_name" class="form-control" />
          </div>
          <div class="form-group">
            <label>Last Name</label>
            <input type="text" v-model="form.last_name" class="form-control" />
          </div>
          <div class="form-group">
            <label>Email</label>
            <input type="text" v-model="form.email" class="form-control" />
          </div>
          <div class="form-group">
            <label>Phone</label>
            <input type="text" v-model="form.phone" class="form-control" />
          </div>
          <div style="text-align: center">
            <button class="btn btn-primary" @click="updateProfile()" :disabled="loading">Save Changes</button>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 48px;">
        <div class="col-12 col-md-6" style="margin: 0 auto">
          <h3>Change Password</h3>
          <div class="form-group">
            <label>New Password</label>
            <input type="password" v-model="form.password" class="form-control" />
          </div>
          <div class="form-group">
            <label>Reenter Password</label>
            <input type="password" v-model="form.password_repeat" class="form-control" />
          </div>
          <div style="text-align: center">
            <button class="btn btn-primary" @click="updatePassword()" :disabled="loading">Save Changes</button>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from "@/components/SubHeader.vue";
import Swal from "sweetalert2";

export default {
  components: {
    BaseLayout,
    SubHeader
  },
  data() {
    return {
      loading: false,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        password_repeat: null
      }
    }
  },
  watch: {
    'form.phone'(newValue) {
      newValue = newValue.replace( /[^\d]/g, '' ).trim()
      if ( newValue.length > 0 ) {
        newValue = '+' + newValue;
      }
      this.form.phone = newValue;
    }
  },
  mounted() {
    document.title = 'My Profile - Mane Street Market';
    window.scrollTo(0, 0);
    this.form.first_name = this.$store.state.profile.first_name;
    this.form.last_name = this.$store.state.profile.last_name;
    this.form.email = this.$store.state.user.email;
    this.form.phone = this.$store.state.user.phoneNumber;
  },
  methods: {
    async updateProfile() {
      this.loading = true;
      try {
        await axios.post('/api/update-profile', {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          phone_number: this.form.phone
        });
        Swal.fire({
          text: 'Information has been updated!'
        });
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: 'Error updating profile',
          text: err.response?.data?.error?.message
        })
      } finally {
        this.loading = false;
      }
    },
    async updatePassword() {
      this.loading = true;
      try {
        await axios.post('/api/update-password', {
          password: this.form.password,
          password_repeat: this.form.password_repeat
        });
        Swal.fire({
          text: 'Password has been changed'
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
